* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #f3f4f9;
}

.css-11u3b1d-MuiSpeedDial-root .MuiSpeedDial-actions {
    position: absolute;
    margin-top: -0.5rem !important;
}

.css-14f02pv-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    box-shadow: none !important;
    background-color: white !important;
}

.MuiTableSortLabel-root {
    color: white !important;
}

.MuiTableSortLabel-icon {
    color: white !important;
}